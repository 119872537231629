import {
  ACHIEVEMENT_WALL_ENABLED,
  CATALOG_VIEW_ENABLED,
  GDPR_ENABLED,
  LEADERBOARD_ENABLED,
  SEARCH_ENABLED,
  ANONYMOUS_LEADEBOARD_ENABLED,
  SSO,
  RANKING_QUESTIONS_DISABLED,
} from "../constants/settings";
import getClient from "../client";
import { getCurrentSelectedTenant } from "../helpers/user";
import { SITE_SETTINGS } from "../routes";
import { SAML_APPROVE } from "../admin/constants/settings";

const currentTenantName = getCurrentSelectedTenant();

export default {
  state: {
    // settings
    darkLogoBackground: false,

    [ACHIEVEMENT_WALL_ENABLED]: false,
    [CATALOG_VIEW_ENABLED]: false,
    [GDPR_ENABLED]: false,
    [LEADERBOARD_ENABLED]: false,
    [SEARCH_ENABLED]: false,
    [ANONYMOUS_LEADEBOARD_ENABLED]: false,
    [RANKING_QUESTIONS_DISABLED]: false,
    [SAML_APPROVE]: false,
    roleSelectionRequired: false,

    buildAdviceVideoId: "",
    dojoPreviewVideoId: "",
    journeyPreviewVideoId: "",

    // misc
    publishableKey: null,
    numberOfUsers: 0,

    // modes
    [SSO]: false,
    consumerMode: false,
    trialMode: false,
    perLessonMode: false,
    enterpriseMode: false,
    subdomainName: "production",

    // not from settings
    isBalanceEmpty: false,

    // info related
    disclaimerText: "",
    contacts: "",
    smallLogoUrl: "",
    thumbLogoUrl: "",

    hasUserAccess: false,
    hasAdminAccess: false,
    email: "",
    signOutPath: "",
    impersonating: false,
    isSjAdmin: false,

    features: [],

    isLoaded: false,

    currentTenantName: currentTenantName || "",

    // oauth
    googleClientId: null,
    githubClientId: null,

    //network
    isNetworkError: false,

    isCookiesBannerClosed: false,
  },

  reducers: {
    updateState: (state, payload) => ({ ...state, ...payload }),
    set: (state, payload) => ({ ...state, ...payload }),
    activate: (state, name) => ({ ...state, [name]: true }),
    disable: (state, name) => ({ ...state, [name]: false }),
    setEmail: (state, email) => ({ ...state, email }),
    setLogoUrls: (state, { smallLogoUrl, thumbLogoUrl }) => ({
      ...state,
      smallLogoUrl,
      thumbLogoUrl,
    }),

    // used in emptyBalanceModal.js, activityNotes.js
    setIsBalanceEmpty: (state, isBalanceEmpty) => ({
      ...state,
      isBalanceEmpty,
    }),
    setIsLoaded: (state, isLoaded) => ({ ...state, isLoaded }),
  },

  effects: dispatch => ({
    setSelectedTenant(tenantName) {
      dispatch.site.set({ currentTenantName: tenantName });
    },
    async loadAsync() {
      const { data } = await getClient().get(SITE_SETTINGS);

      if (data.site) {
        dispatch.site.updateState(data.site);
      }

      dispatch.site.setIsLoaded(true);
    },
    async updateNetworkError(isNetworkError) {
      dispatch.site.set({ isNetworkError });
    },

    closeCookiesBanner() {
      dispatch.site.set({ isCookiesBannerClosed: true });
    },
  }),
};

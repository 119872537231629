import { ssoEnabled } from "./ssoEnabled";
import { getCurrentSelectedTenant, getIsSjAdminFromStorage } from "./user";
import { retrieveSvcAuthToken } from "./authService";

export const getHeaders = async (noAuth = false) => {
  const svcAuthToken = !noAuth ? await retrieveSvcAuthToken() : null;
  const locale = localStorage.getItem("i18nextLng");
  const selectedTenant = getCurrentSelectedTenant();
  const isSjAdmin = getIsSjAdminFromStorage();

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  if (svcAuthToken) {
    headers["Authorization"] = `Bearer ${svcAuthToken}`;
  }

  if (locale) {
    headers["locale"] = locale;
  }

  if (selectedTenant) {
    headers["SelectedTenant"] = selectedTenant;
  }

  if (isSjAdmin) {
    headers["IsSjAdmin"] = true;
  }

  headers["ssoEnabled"] = ssoEnabled().toString();

  return headers;
};

export const buildHeaderForUpload = async () => {
  const headers = await getHeaders();
  headers["Content-Type"] = "multipart/form-data";
  return headers;
};

import React, { useEffect, useState } from "react";
import T from "prop-types";
import { useDispatch } from "react-redux";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import { saveSelectedTenant } from "../../../../helpers/user";
import { retrieveTenantList } from "../../../../helpers/authService";

import S from "./ChooseTenantModal.module.scss";

const ChooseTenantModal = ({ isOpen, setIsOpen }) => {
  const [tenants, setTenants] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchTenants = async () => {
      const tenantList = await retrieveTenantList();
      setTenants(tenantList);
    };

    fetchTenants();
  }, []);

  const handleSelectTenant = async index => {
    const selectedTenant = tenants[index];

    saveSelectedTenant(selectedTenant);
    dispatch.site.setSelectedTenant(selectedTenant);
    setIsOpen(false);
  };

  return (
    <Modal
      backdropClassName={S.backdrop}
      backdrop={true}
      className={S.root}
      isOpen={isOpen}
      centered
    >
      <ModalHeader tag="div">Which tenant would you like to log into?</ModalHeader>

      <p className={S.subHeader}>You may switch tenants at any time from your Account Profile.</p>

      <ModalBody testkey="select-tenant-modal">
        {tenants.map((tenant, index) => {
          return (
            <Button
              key={index}
              className={S.button}
              block
              outline
              testkey={`select-tenant-button-${tenant}`}
              onClick={() => handleSelectTenant(index)}
            >
              {tenant}
            </Button>
          );
        })}
      </ModalBody>
    </Modal>
  );
};

ChooseTenantModal.propTypes = {
  isOpen: T.bool.isRequired,
  setIsOpen: T.func.isRequired,
};

export default ChooseTenantModal;

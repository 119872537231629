import { toast } from "react-toastify";
import i18next from "i18next";
import {
  FORBIDDEN,
  NOT_FOUND,
  PAYMENT_REQUIRE,
  SERVER_ERROR,
  UNAUTHORIZED,
  UNPROCESSABLE_ENTITY,
} from "../constants/httpErrorStatuses";

const STATUS_MESSAGES = {
  [PAYMENT_REQUIRE]: i18next.t("errors.402"),
  [FORBIDDEN]: i18next.t("errors.403"),
  [NOT_FOUND]: i18next.t("errors.404"),
  [UNPROCESSABLE_ENTITY]: i18next.t("errors.422"),
};

export const generateMessageFromStatus = ({ status, data }) => {
  let message;

  // Ignore unauthorized errors, as the interceptor will automatically attempt to refresh the token and retry the request
  if (status === UNAUTHORIZED) {
    return;
  }

  if (status >= SERVER_ERROR) {
    message = i18next.t("errors.somethingWentWrong");
  } else if (STATUS_MESSAGES[status]) {
    message = STATUS_MESSAGES[status];
  }

  if ([FORBIDDEN, UNPROCESSABLE_ENTITY].includes(status)) {
    // user should know why he is not allowed
    if (data && data.errors && typeof data.errors === "string") {
      message = data.errors;
    }
  }
  return message;
};

export const fetchErrorToastHandler = ({ message, status, useToast }) => {
  if (message && !toast.isActive(status)) {
    if (useToast) {
      toast.error(message, { toastId: status });
    } else {
      toast.dismiss(status);
    }
  }
};

export const networkErrorToastHandler = () => {
  const networkError = "network-error";
  if (!toast.isActive(networkError)) {
    toast.error(i18next.t("errors.networkProblem"), {
      toastId: networkError,
    });
  }
};

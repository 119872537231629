import getClient from "../client";
import { API_VARIABLES_PATH } from "../routes/user";

const setGlobalIds = data => {
  window.awsCognitoUserPoolAdminId = data.awsCognitoUserPoolAdminId;
  window.awsCognitoUserPoolId = data.awsCognitoUserPoolId;
  window.awsCognitoClientAdminId = data.awsCognitoClientAdminId;
  window.awsCognitoClientId = data.awsCognitoClientId;
};

export default {
  state: {
    isVariablesLoaded: false,
  },
  reducers: {
    set: (state, payload) => ({ ...state, ...payload }),
  },

  effects: dispatch => ({
    async loadAsyncVariables() {
      try {
        const { data } = await getClient({ noAuth: true }).get(API_VARIABLES_PATH);
        setGlobalIds(data);
        dispatch.variables.set({ isVariablesLoaded: true });
      } catch (error) {
        console.error({ error });
        dispatch.site.updateNetworkError(true);
      }
    },
  }),
};

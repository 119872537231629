import {
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
  CognitoIdToken,
  CognitoAccessToken,
  CognitoRefreshToken,
} from "amazon-cognito-identity-js";
import getClient from "../client";
import { INIT_LOGIN_PATH, AFTER_SIGN_OUT_PATH } from "../routes/landing";
import { setUserArtifacts, cleanUserArtifacts, getEmailFromJWT } from "./user";
import { retrieveSvcAuthToken } from "./authService";
import { cognitoPoolData } from "../constants/cognitoPoolData";
import humps from "humps";
import { ssoEnabled } from "./ssoEnabled";

const AmazonCognitoIdentity = require("amazon-cognito-identity-js");

/**
 * Signs in a user to Cognito using the provided tokens. Follows this up by retrieving the svcAuthToken.
 *
 * @param {Object} tokens - The tokens required for Cognito sign-in.
 * @param {string} tokens.idToken - The ID token for the user.
 * @param {string} tokens.accessToken - The access token for the user.
 * @param {string} tokens.refreshToken - The refresh token for the user.
 * @returns {Promise<void>} A promise that resolves when the sign-in process is complete.
 */
export const doCognitoSignIn = async tokens => {
  const email = getEmailFromJWT(tokens.idToken);

  const cognitoUserSession = new CognitoUserSession({
    IdToken: new CognitoIdToken({ IdToken: tokens.idToken }),
    AccessToken: new CognitoAccessToken({ AccessToken: tokens.accessToken }),
    RefreshToken: new CognitoRefreshToken({ RefreshToken: tokens.refreshToken }),
  });

  getCognitoUser({ email }).setSignInUserSession(cognitoUserSession);

  await retrieveSvcAuthToken();
};

export const getCognitoUserPool = () => {
  return new CognitoUserPool(cognitoPoolData());
};

export const getCognitoJwt = async (forceRefresh = false) => {
  const user = getCognitoCurrentUser();
  if (!user) {
    // No current user, so no Jwt to return
    return null;
  }
  const session = await getUserSession(user);
  let token = session.getIdToken();

  // If forceRefresh OR the current token is expired, try to get a new one
  const now = new Date();
  if (forceRefresh || now.getTime() > token.getExpiration() * 1000) {
    token = await refreshCognitoUserSession(user, session.getRefreshToken());
  }

  return token.getJwtToken();
};

export const getCognitoCurrentUser = () => {
  return getCognitoUserPool().getCurrentUser();
};

export const cognitoLogout = () => {
  const user = getCognitoCurrentUser();
  if (user) {
    user.signOut();
  }
};

const getUserSession = user => {
  return new Promise((resolve, reject) => {
    user.getSession((err, session) => {
      if (err) return reject(err);

      resolve(session);
    });
  });
};

const refreshCognitoUserSession = async (cognitoUser, refresh_token) => {
  return new Promise(resolve => {
    cognitoUser.refreshSession(refresh_token, (err, session) => {
      if (err) {
        console.log(err);
        cognitoLogout();
        cleanUserArtifacts();
        window.location.href = AFTER_SIGN_OUT_PATH;
      } else {
        let idToken = session.getIdToken();
        resolve(idToken);
      }
    });
  });
};

export const getCognitoUser = ({ email }) => {
  const userPool = getCognitoUserPool();

  const userData = {
    Username: decodeURIComponent(email.toLowerCase()),
    Pool: userPool,
  };

  return new CognitoUser(userData);
};

export const cognitoLogin = ({ email, password }) => {
  const userData = {
    Username: email.toLowerCase(),
    Password: password,
  };

  const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(userData);
  const cognitoUser = getCognitoUser({ email });
  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: async result => {
        const params = {
          access_token: result.accessToken.jwtToken,
          refresh_token: result.refreshToken.token,
          id_token: result.idToken.jwtToken,
          ssoEnabled: ssoEnabled(),
        };
        try {
          const {
            data: { code },
          } = await getClient().post(INIT_LOGIN_PATH, params);
          resolve(code);
        } catch (err) {
          reject(err.message);
        }
      },
      onFailure: err => reject(err.message),
    });
  });
};

export const setLoginToken = tokensData => {
  const tokens = humps.camelizeKeys(tokensData);
  setUserArtifacts(tokens);
};
